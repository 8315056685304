import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import DrawerMenu from '../DrawerMenu';
import MenuButton from '../MenuButton';

const useClasses = createClasses((theme) => ({
	root: {
		display: 'flex'
	},
	nav: {},
	button: {
		whiteSpace: 'nowrap',
		'& > .MuiButton-endIcon': {
			marginRight: 0
		}
	}
}), {
	name: 'RaAppBarNav'
});

function AppBarNav(props) {
	const {
		classes: classesProp,
		className,
		DrawerMenuNavItemProps,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<DrawerMenu
			{...rest}
			className={classes.root}
			DrawerMenuNavItemProps={{
				...DrawerMenuNavItemProps,
				MenuButtonComponent: MenuButton,
				classes: {
					...classes,
					root: classes.nav
				}
			}}
		/>
	);
}

AppBarNav.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string
};

export default React.memo(AppBarNav);
