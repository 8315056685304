/* global WEBPACK_IS_PUBLIC */

import { createClasses, useTheme } from 'styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from 'react-app/icons/Phone';
import WatchIcon from '@mui/icons-material/WatchLaterOutlined';
import LocationIcon from '@mui/icons-material/LocationOn';
import Link from 'components/Link';
import Brand from 'components/Brand';
import ContactIcons from 'components/ContactIcons';
import AppFooterContainer from 'components/AppFooter/AppFooterContainer';
import AppFooterContent from 'components/AppFooter/AppFooterContent';
import AppFooterBottom from 'components/AppFooter/AppFooterBottom';
import { useCookieConsent } from 'lib/cookie-consent';
import { logo_hw_cz, logo_hw_cz_mono } from 'config/images';
import { appData } from 'config/data';

const { messages, contact } = appData;

const useClasses = createClasses((theme) => ({
	root: {
		fontSize: '0.875rem',
		color: theme.vars.palette.text.primary,
		maxWidth: theme.config.containerMaxWidth,
		[theme.breakpoints.up('md')]: {
			marginX: 'auto',
			paddingX: theme.config.containerSpacing
		},
		[theme.breakpoints.down('md')]: {
			textAlign: 'center'
		}
	},
	social: {
		fontSize: '3rem'
	},
	icon: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(.5)
	},
	text: {
		display: 'flex',
		textAlign: 'left',
		alignItems: 'center',
		alignContent: 'center',
		gap: .5
	}
}), {
	name: 'CzechAppFooter'
});

const phone = contact.phone.czech;
const email = contact.email.czech;

export default function CzechAppFooter(props) {
	const classes = useClasses(props);
	const theme = useTheme();
	const { darkMode } = theme.config;

	const { handleCookieConsentReset } = useCookieConsent();

	function handleCookieConsentClick(event) {
		handleCookieConsentReset(event);
	}

	return (
		<AppFooterContainer>
			<AppFooterContent direction={{xs: 'column', md: 'row'}}>
				<Stack width={{xs: 'auto', desktop: '40%'}} gap={.5}>
					<address>
						<Typography color="inherit" variant="h6" component="div" mb={2}>
							{messages.footer.contact.title}
						</Typography>
						<Typography
							className={classes.text}
							color="inherit"
							fontSize="inherit"
							lineHeight={1.2}
							marginBottom={0.5}
							maxWidth={{xs: 200, md: 'none'}}
						>
							<LocationIcon className={classes.icon} fontSize="inherit"/>{' '}
							<Link color="text.primary" href={appData.contact.map.chemnitz}>
								{messages.footer.contact.street}
							</Link>
						</Typography>
					</address>
					<Typography
						className={classes.text}
						color="inherit"
						fontSize="inherit"
						noWrap
					>
						<PhoneIcon className={classes.icon} fontSize="inherit"/>{' '}
						<Link color="text.primary" href={`tel:${phone}`}>
							{phone}
						</Link>
					</Typography>
					<Typography
						className={classes.text}
						color="inherit"
						fontSize="inherit"
						noWrap
					>
						<EmailIcon className={classes.icon} fontSize="inherit"/>{' '}
						<Link color="text.primary" href={`mailto:${email}`}>
							{email}
						</Link>
					</Typography>
					<Typography
						className={classes.text}
						color="inherit"
						fontSize="inherit"
						lineHeight={1.2}
						marginTop={0.5}
						maxWidth={{xs: 240, md: 'none'}}
					>
						<WatchIcon className={classes.icon} fontSize="inherit"/>{' '}
						{messages.footer.contact.openingHours}
					</Typography>
				</Stack>

				<Stack
					width={{xs: 'auto', desktop: '20%'}}
					alignItems="center"
					marginY="auto"
					marginTop={{xs: 4, md: 'auto'}}
				>
					<Brand
						width={212}
						height={90}
						src={darkMode ? logo_hw_cz_mono?.src : logo_hw_cz?.src}
					/>
				</Stack>

				<Stack
					justifyContent="flex-end"
					marginY="auto"
					width={{xs: 'auto', desktop: '40%'}}
					direction="row"
				>
					<ContactIcons
						className={classes.social}
						shop={false}
						youtube={false}
						pinterest
					/>
				</Stack>
			</AppFooterContent>
			<AppFooterBottom
				disableContactBtn
				disableTermsOfBusiness={WEBPACK_IS_PUBLIC}
			>
				<Stack direction="row" width="100%" justifyContent="flex-end">
					<Link color="text.primary" component="button" onClick={handleCookieConsentClick}>
						{messages.cookieConsent.btnSettings}
					</Link>
				</Stack>
			</AppFooterBottom>
		</AppFooterContainer>
	);
}
