import { useGa } from 'lib/ga';
import { useCookieConsent } from 'lib/cookie-consent';
import { useDidMount } from 'react-app/hooks';
import CookieConsent from 'react-app/components/CookieConsent';
import Link from 'components/Link';
import { appData } from 'config/data';

const { messages } = appData;

const description = (
	<>
		Používáme soubory cookies abychom Vám mohli nabídnout lepší služby.
		Některé z nich jsou nezbytné pro zajištění funkčnosti webu,
		zatímco jiné nám pomáhají vylepšovat tento web a vaši zkušenosti s ním.<br/>
	</>
);

export default function CzechCookieConsent(props) {
	const { initGa } = useGa();
	const { handleCookieConsentClose } = useCookieConsent();

	useDidMount(initGa);

	return (
		<CookieConsent
			LinkComponent={Link}
			linkColor="primary"
			onClose={handleCookieConsentClose}
			onAccept={initGa}
			disableDialog
			btnPrivacyInfoLink={appData.pages.privacyPolicy.pathname}
			btnPrivacyInfoTitle={messages.cookieConsent.btnPrivacyInfo}
			btnMoreInfoTitle={messages.cookieConsent.btnMoreInfo}
			btnAcceptTitle={messages.cookieConsent.btnAccept}
			btnDeclineTitle={messages.cookieConsent.btnDecline}
			description={description}
		/>
	);
}
